import {Client} from '../../../shared/utilities/type-helpers';
const client: Client = {clientType: 'terrific'};

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyA9aJYQPdeKcyiDPVYSVUdcwy21d3g9kxo',
    authDomain: 'terrific-shadow.firebaseapp.com',
    projectId: 'terrific-shadow',
    storageBucket: 'terrific-shadow.appspot.com',
    messagingSenderId: '1055594518163',
    appId: '1:1055594518163:web:41b0a4cd819eeefd459f66',
    measurementId: 'G-WSRCN7K492',
  },
  useEmulators: false,
  maxUplodedImageSize: 5000001,
  allowImageExt: '.jpg,.png,.jpeg',
  featureFlags: {longPolling: false},
  client,
};
